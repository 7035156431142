import axios from "axios";
import { useState } from "react";
import styled from "styled-components";

export default function App() {
  const [image, setImage] = useState(null);

  async function getImage() {
    const url = "https://dog.ceo/api/breeds/image/random";
    try {
      const response = await axios.get(url);
      setImage(response.data.message);
      console.log(response.data.message);
    } catch (error) {
      console.log(error);
    }
  }

  if (image) {
    return (
      <Container>
        <Header>RobsHappy</Header>
        <Main>
          <Button onClick={getImage}>Press Me</Button>
          <Image src={image} alt="Random Dog" />
        </Main>
      </Container>
    );
  } else {
    return (
      <Container>
        <Header>RobsSad</Header>
        <Main>
          <Button onClick={getImage}>Press Me</Button>
        </Main>
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #aaaaaa;
`;
const Header = styled.div`
  display: flex;
  width: 100%;
  height: 7vh;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: black;
  justify-content: center;
  align-items: center;
  font-family: "Press Start 2P", sans-serif;
  color: white;
`;

const Main = styled.div`
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  width: 150px;
  height: 5vh;
  margin-bottom: 1rem;
  border-radius: 15px;
  background-color: black;
  font-family: "Press Start 2P", sans-serif;
  font-size:10px;
  color: white;
`;

const Image = styled.img`
  max-width: 95%;
  height: 50vh;
`;
